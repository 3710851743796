<template>
  <div class="text-center p-3">
    <div class="mb-2">
      <img
        width="218px"
        height="125px"
        :src="logo"
        class="d-inline-block align-top"
        alt="logo"
      >
    </div>
    <div class="mb-1">
      <h3 class="text-center font-weight-bolder text-body-heading">
        {{ $t('Welcome to EasyRentals Backoffice!') }}
      </h3>
    </div>
    <div class="mb-1">
      <p class="text-center font-weight-normal mb-1">
        {{ $t('Easy Rentals Backoffice is a Rental Asset Management system designed and developed by Relentless Agency.')
        }}
      </p>
    </div>
    <div class="mb-1">
      <img
        width="143px"
        height="29px"
        class="d-inline-block align-top"
        alt="relentless"
        :src="relentless"
      >
    </div>
    <div class="mb-1">
      <b-button
        class="saveBtn font-medium-1 font-weight-bolder mb-1"
        variant="success"
        @click="$router.push({ name: 'settings' })"
      >
        {{ $t('SET UP MY COMPANY') }}
      </b-button>
    </div>
    <div class="mb-1">
      <b-button class="saveBtn font-medium-1 font-weight-bolder mb-1">
        {{ $t('Watch Video tours') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ability from '@/libs/acl/ability'
import { BButton } from 'bootstrap-vue'
import logo from '../../../assets/images/logo/easyRentalsBig.svg'
import relentless from '../../../assets/images/logo/relentlessAgency.svg'
// eslint-disable-next-line import/no-cycle
console.log(ability)

export default {
  name: 'Dashboard',
  components: {
    BButton,
  },
  data() {
    return {
      logo,
      relentless,
    }
  },
  created() {
    this.priceTierList()
  },
  methods: {
    ...mapActions('listModule', ['priceTierList']),
  },
}
</script>

<style scoped></style>
